import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_overal-styling.scss";

const Privacy = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="This Privacy Policy covers what we collect and how we use, disclose, transfer and store your information."
      />
      <section className="page-heading">
        <div className="container">
          <h1>Privacy Policy</h1>
        </div>
      </section>
      <section>
        <div className="container privacy-wrapper">
          <p className="text-large">
            Your privacy is important to The Simple Department (“The Simple
            Department”). This Privacy Policy covers what we collect and how we
            use, disclose, transfer and store your information.{" "}
          </p>
          <p className="pricay-title">1. Identity of The Simple Department</p>
          <p>
            If there are any questions regarding this Privacy Policy you may
            contact us using the information below.<br></br>
            <br></br>The Simple Department<br></br>Suite 311<br></br>63 6th
            Avenue West<br></br>
            Vancouver, British Columbia<br></br>Canada<br></br>Phone:{" "}
            <a href="tel:+1 877 375 8542">+1 877 375 8542</a>
            <br></br>E-mail:{" "}
            <a href="mailto:hello@thesimpledepartment.com">
              hello@thesimpledepartment.com
            </a>
            <br></br>
            <br></br>Our customers may submit inquiries regarding personal data
            protection, privacy and security matters to CEO Hussein Samnani.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">2. What information do we collect?</p>
          <p>
            You may visit our site anonymously.<br></br>
            If you choose to register on our website, four categories of data to
            and on behalf of you will be processed:<br></br>
            <br></br>
            <strong>“Account data”</strong>
            <br></br>
            When you register for an account on our site, place an order,
            subscribe to our newsletter or respond to a survey, basic contact
            details are collected such as the e-mail address and name of your
            contact person, company name, address, phone number, Business
            Number, preferred language and currency, any purchase order number,
            any e-mail address of invoice receivers and masked credit card or
            bank account details.<br></br>
            <br></br>
            <strong>“Configuration data”</strong>
            <br></br>
            We collect your direct input to our cloud service ClickUp Project
            Management Platform (the “Service”) after login, like the domain
            name(s) of the website(s) associated with your projects with The
            Simple Department and the names of team members and partners who
            will have access to the projects (“End Users”).<br></br>
            <br></br>
            <strong>“End User Data”</strong>
            <br></br>
            Data generated by End Users browsing our website(s) and our Project
            Management Platform.<br></br>
            <br></br>
            <strong>
              The Service automatically creates and stores meta data on basis of
              the other types of data, e.g.:
            </strong>
            <br></br>
            Subscription data, like start date, latest invoice date and the
            result of a mandatory Business Registration and Tax number
            validation. Issued invoices are stored so that you may access any
            issued invoices from within the Service Manager. Aggregated
            statistical data on End User consents.<br></br>
            <br></br>
            You will be informed by The Simple Department about relevant changes
            concerning the Service, such as the implementation of additional
            functions, by e-mail, if you subscribe to The Simple Department’s
            newsletter from the account settings page in the Service Manager.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">
            3. What do we use your information for?
          </p>
          <p>
            Any of the information we collect from you may be used for one or
            more of the following purposes:<br></br>
            <br></br>
          </p>
          <ul>
            <li>
              3.1. To personalize your experience (the information will help The
              Simple Department better respond to your individual needs);
            </li>
            <li>
              3.2. To improve our website (Cybot continually strives to improve
              our website offerings based on the information and feedback we
              receive from our customers);
            </li>
            <li>3.3. To identify you as a contracting party;</li>
            <li>
              3.4. To enable secure login for you in the Service Manager at
              TheSimpleDepartment.com;{" "}
            </li>
            <li>
              3.5. To establish a primary channel of communication with you;{" "}
            </li>
            <li>
              3.6. To enable The Simple Department to issue valid invoices and
              to process transactions (your information will not be sold,
              exchanged, transferred, or given to any other company for any
              reason whatsoever, without your consent, other than for the
              express purpose of delivering the service requested);{" "}
            </li>
            <li>3.7. To enable automated handling of the subscriptions; </li>
            <li>
              3.8. To send periodic e-mails (The e-mail address you provide for
              order processing, may be used to send you information and updates
              pertaining to your order, in addition to receiving occasional
              company news (if accepted), updates, related product or service
              information, etc.) If at any time you would like to unsubscribe
              from receiving future e-mails, you can cancel your account after
              login by clicking on “Cancel my account”.
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">4. Legal basis</p>
          <ul>
            <li>
              4.1. EU General Data Protection Regulation (GDPR) The processing
              of your data is either based on your consent or in case the
              processing is necessary for the performance of a contract to which
              you are a party, or in order to take steps at your request prior
              to entering into a contract, cf. GDPR art. 6(1)(a)-(b).
              <br></br>
              <br></br>
              If the processing is based on your consent, you may at any time
              withdraw your consent by contacting us using the contact
              information in clause 1. In order to enter into a contract
              regarding the purchase of The Simple Department’s Service, you
              must provide us with the required personal data. If you do not to
              provide us with all the required information, it will not be
              possible to deliver the Service.<br></br>
              <br></br>
            </li>
            <li>
              4.2. California Online Privacy Protection Act Compliance Because
              The Simple Department values your privacy we have taken the
              necessary precautions to be in compliance with the California
              Online Privacy Protection Act. We therefore will not distribute
              any personal information to outside parties without your consent
              except as stated in clause 7.<br></br>
              <br></br>As part of the California Online Privacy Protection Act,
              all users of our website may make any changes to their information
              at any time by logging into their account and navigating to the
              “profile page”.<br></br>
              <br></br>
            </li>
            <li>
              4.3. Children’s Online Privacy Protection Act Compliance The
              Simple Department is in compliance with the requirements of the
              Children’s Online Privacy Protection Act. We will not
              intentionally collect any information from anyone under 13 years
              of age. Our website, products and services are all directed at
              people who are at least 13 years old or older.
            </li>
          </ul>

          <span className="divider"></span>
          <p className="pricay-title">5. How do we protect your information?</p>
          <p>
            The Simple Department implements the following technical, physical
            and organizational measures to maintain the safety of your personal
            data against accidental or unlawful destruction or accidental loss,
            alteration, unauthorized use, unauthorized modification, disclosure
            or access and against all other unlawful forms of processing.
            <br></br>
            <br></br>
          </p>
          <ul>
            <li>
              5.1. Availability The Service utilizes the extensive features of
              the cloud environment to ensure high availability, like full
              redundancy, load balancing, automatic capacity scaling, continuous
              data backup and geo-replication along with a traffic manager for
              automatic geographical failover on datacenter level disasters. All
              failover mechanisms are fully automated.<br></br>
              <br></br>No personal data is stored permanently outside The Simple
              Department’s cloud platforms. The physical security is thereby
              maintained by The Simple Department’s subcontractors, see clause
              7. Microsoft’s datacenters comply with industry standards such as
              ISO 27001 for physical security and availability, e.g. by using
              security staff around the clock, two-factor access control using
              biometric and card readers, barriers, fencing, security cameras
              and other measures.<br></br>
              <br></br>
            </li>
            <li>
              5.2. Integrity To ensure integrity, all data transits are
              encrypted to align with best practices for protecting
              confidentiality and data integrity. E.g. all supplied credit card
              information is transmitted via Secure Socket Layer (SSL)
              technology and then encrypted into our payment gateway provider’s
              database only to be accessible by those who are authorized to
              access such systems and who are required to keep the information
              confidential. For data in transit, the Service uses
              industry-standard transport protocols between devices and
              Microsoft datacenters and within datacenters themselves.
              <br></br>
              <br></br>
            </li>
            <li>
              5.3. Confidentiality All personnel are subject to full
              confidentiality and any subcontractors and subprocessors are
              required to sign a confidentiality agreement if not full
              confidentiality is part of the main agreement between the parties.
              <br></br>
              <br></br>Whenever personal data is accessed by authorized
              personnel the access is only possible over an encrypted
              connection. When accessing the data in a database, the IP number
              of the person accessing the data must also be pre-authorized to
              obtain access.<br></br>
              <br></br>Any device being used to access personal data is login
              protected by The Simple Department’s Azure Active Directory (AAD),
              Microsoft’s cloud based identity and access management service,
              and has The Simple Department’s corporate antivirus solution
              installed. If any personal data are temporarily stored on a
              device, the storage unit on the device must also be strongly
              encrypted.<br></br>
              <br></br>On premise devices storing personal data temporarily is
              at all times, except when not being actively used or relocated
              under uninterrupted supervision, locked in a safe. Personal data
              are never stored on mobile media like USB sticks and DVD’s.
              <br></br>
              <br></br>
            </li>
            <li>
              5.4. Transparency The Simple Department will at all times keep you
              informed about changes to the processes to protect data privacy
              and security, including practices and policies. You may at any
              time request information on where and how data is stored, secured
              and used. The Simple Department will also provide the summaries of
              any independent audits of the Service.<br></br>
              <br></br>
            </li>
            <li>
              5.5. Isolation All access to personal data is blocked by default,
              using a zero privileges policy. Access to personal data is
              restricted to individually authorized personnel. The Simple
              Department’s Security and Privacy Officer issues authorizations
              and maintains a log of granted authorizations. Authorized
              personnel are granted a minimum access on a need-to-have basis
              through our AAD.<br></br>
              <br></br>
            </li>
            <li>
              5.6. The ability to intervene The Simple Department enables your
              rights of access, rectification, erasure, blocking and objection
              mainly by providing built-in functions for data handling in the
              Service Manager, by offering the option to send instructions
              through The Simple Department’s helpdesk and also by informing
              about and offering the customer the possibility of objection when
              The Simple Department is planning to implement changes to relevant
              practices and policies. The overall responsibility for data
              security lies with The Simple Department’s Data Protection Officer
              who educates and updates all personnel on the data security
              measures outlined in The Simple Department’s security handbook and
              this Privacy Policy.<br></br>
              <br></br>
            </li>
            <li>
              5.7. Monitoring The Simple Department uses security reports to
              monitor access patterns and to proactively identify and mitigate
              potential threats. Administrative operations, including system
              access, are logged to provide an audit trail if unauthorized or
              accidental changes are made. System performance and availability
              is monitored from both internal and external monitoring services.
              <br></br>
              <br></br>
            </li>
            <li>
              5.8. Personal Data breach notification In the event that your data
              is compromised, The Simple Department will notify you and
              competent Supervisory Authority(ies) within 72 hours by e-mail
              with information about the extent of the breach, affected data,
              any impact on the Service and The Simple Department’s action plan
              for measures to secure the data and limit any possible detrimental
              effect on the data subjects.<br></br>
              <br></br>“Personal data breach” means a breach of security leading
              to the accidental or unlawful destruction, loss, alteration,
              unauthorized disclosure of, or access to, personal data
              transmitted, stored or otherwise processed in connection with the
              provision of the Service.<br></br>
              <br></br>
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">6. How we use cookies</p>
          <p>
            See The Simple Department’s Cookie Declaration at{" "}
            <a href="www.cookiebot.com/en/cookie-declaration" target="_blank">
              www.cookiebot.com/en/cookie-declaration
            </a>{" "}
            for information on the cookies we use.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">
            7. Do we disclose any information to outside parties?
          </p>
          <p>
            The Simple Department does not sell, trade or otherwise transfer to
            outside parties any personally identifiable information.<br></br>
            <br></br>This does not include trusted third parties or
            subcontractors who assist us in operating our website, conducting
            our business, or servicing you. Such trusted parties may have access
            to personally identifiable information on a need-to-know basis and
            will be contractually obliged to keep your information confidential.
            <br></br>
            <br></br>We may also release your information when we believe
            release is appropriate to comply with the law, enforce our site
            policies, or protect our or others’ rights, property, or safety.
            Furthermore, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other uses.
            <br></br>
            <br></br>
          </p>
          <ul>
            <li>
              7.1. Subcontractors/trusted third parties The subcontractors
              Microsoft Ireland Operations Ltd, Dublin, Ireland and Fujitsu A/S,
              Ballerup, Denmark are following and being audited against the
              standards of ISO/IEC 27001. The main subcontractor Microsoft has
              also adopted the international code of practice for cloud privacy,
              ISO/IEC 27018. The subprocessor E-conomic International A/S is
              certified in “International Standards on Assurance Engagements
              3000” (ISAE 3000).<br></br>
              <br></br>The Simple Department will monitor subcontractors’ and
              subprocessors’ maintenance of these standards and audits to ensure
              that data protection requirements are fulfilled.<br></br>
              <br></br>Any intended changes concerning the addition or
              replacement of subcontractors or subprocessors handling personal
              data will be announced to you with at least 3 months’ notice. You
              retain at all times the possibility to object to such changes or
              to terminate the contract with The Simple Department.
              <br></br>
              <br></br>
            </li>
            <li>
              7.2 Legally required disclosure The Simple Department will not
              disclose the customer’s data to law enforcement except when
              instructed by you or where it is required by law. When governments
              make a lawful demand for customer data from The Simple Department,
              The Simple Department strives to limit the disclosure. The Simple
              Department will only release specific data mandated by the
              relevant legal demand.<br></br>
              <br></br>If compelled to disclose your data, The Simple Department
              will promptly notify you and provide a copy of the demand unless
              legally prohibited from doing so.
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">8. Third party links</p>
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked websites. Nonetheless, we seek to protect the integrity of
            our website and welcome any feedback about these websites.{" "}
          </p>
          <span className="divider"></span>
          <p className="pricay-title">9. Where do we store the information?</p>
          <p>
            No stored data will be transferred, backed up and/or recovered by
            The Simple Department outside of the European Union.<br></br>
            <br></br>
          </p>
          <ul>
            <li>
              9.1. Personal data location All data are stored in databases and
              file repositories hosted in an Azure data center at The Simple
              Department’s cloud vendor, Microsoft Canada Operations Ltd in
              Canada. All data are automatically replicated in real time to
              secondary hot failover databases and file repositories in
              Microsoft’s data center in Ontario, Canada.<br></br>
              <br></br>Databases are continuously backed up to enable restore to
              any point in time within a retention period of 35 days. Backups
              are stored on file storage at the same geographical location as
              the database.<br></br>
              <br></br>A copy of the Account Data is also stored in The Simple
              Departments’s cloud accounting system, Amazon Web Services,
              Montreal, Canada, hosted in a datacenter operated by Amazon Web
              Services.<br></br>
              <br></br>
            </li>
            <li>
              9.2. Installation of software on cloud customer’s system No
              installation of software is required to use the Service. The
              login-protected Service Manager is accessible through a standard
              web browser, automatically using an encrypted https-connection for
              all communications between your browser and The Simple
              Department’s server to protect any data from being intercepted
              during network transfers.
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">
            10. Access, data portability, migration, and transfer back
            assistance
          </p>
          <p>
            You may at any time obtain confirmation from The Simple Department
            as to whether or not personal data concerning you are being
            processed.<br></br>
            <br></br>You may at any time order a complete data copy, which you
            may transmit to another controller of the data. Your data will be
            delivered within 10 working days by The Simple Department as
            spreadsheet files in Microsoft Excel-format. Logical relations
            between datasets will be preserved in form of unique identifiers.
            You are required to pay $3,500 CAD + any applicable taxes on
            delivery for each data copy order.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">
            11. Request for rectification, restriction or erasure of the
            personal data
          </p>
          <ul>
            <li>
              11.1. Rectification<br></br>You may at any time obtain without
              undue delay rectification of inaccurate personal data concerning
              you, cf. clause 5.6.<br></br>
              <br></br>
            </li>
            <li>
              11.2. Restriction of processing personal data<br></br>
              You may at any time request The Simple Department to restrict the
              processing of personal data when one of the following applies:
              <ul>
                <li>
                  a.if you contest the accuracy of the personal data, for a
                  period enabling The Simple Department to verify the accuracy
                  of the personal data;
                </li>
                <li>
                  b.if the processing is unlawful and you oppose the erasure of
                  the personal data and request the restriction of their use
                  instead; or
                </li>
                <li>
                  c.if The Simple Department no longer needs the personal data
                  for the purposes of the processing, but they are required by
                  you for the establishment, exercise or defense of legal
                  claims.
                </li>
              </ul>
              <br></br>
            </li>
            <li>
              11.3. Erasure<br></br>You may without undue delay request the
              erasure of personal data concerning you, and The Simple Department
              shall erase the personal data without undue delay when one of the
              following applies:<br></br>
              <ul>
                <li>
                  a.if the personal data are no longer necessary in relation to
                  the purposes for which they were collected or otherwise
                  processed;{" "}
                </li>
                <li>
                  b.if you withdraw your consent on which the processing is
                  based, and where there is no other legal ground for the
                  processing;
                </li>
                <li>
                  c.if you object to the processing in case the processing is
                  for direct marketing purposes; d.if the personal data have
                  been unlawfully processed; or{" "}
                </li>
                <li>
                  e.if the personal data have to be erased for compliance with a
                  legal obligation in EU or national law.
                </li>
              </ul>
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">12. Data retention</p>

          <ul>
            <li>
              12.1. Data retention policy<br></br>Account Data will due to tax
              regulations be retained for up to five full fiscal years from your
              cancellation of your Service account. Configuration Data and
              System Generated Data will be erased immediately when you cancel
              the Service account.<br></br>End User Data will be erased on an
              ongoing basis after 12 months from registration, and immediately
              when you cancel the Service account.<br></br>
              <br></br>
            </li>
            <li>
              12.2. Data retention for compliance with legal requirements
              <br></br>
              You cannot require The Simple Department to change any of the
              default retention periods, except for the reasons for erasure
              pursuant to clause 11.3, but may suggest changes for compliance
              with specific sector laws and regulations.<br></br>
              <br></br>
            </li>
            <li>
              12.3. Data restitution and/or deletion<br></br>No data except
              Account Data will be retained after the termination of the
              contract. You may request a data copy before termination. You must
              not cancel the Service account until the data copy has been
              delivered, as The Simple Department otherwise will not be able to
              deliver the data copy.
            </li>
          </ul>
          <span className="divider"></span>
          <p className="pricay-title">13. Accountability</p>
          <p>
            The Simple Department uses the extensive range of built-in logging
            features and audits trails provided by Microsoft on its Azure cloud
            platform. The Simple Department also logs all system updates,
            configuration changes and access to provide an audit-trail if
            unauthorized or accidental changes are made. You may request a data
            protection audit performed by an independent third party who is also
            accepted by The Simple Department. You will pay $12,500 plus
            applicable taxes for an audit request along with $500 per hour The
            Simple Department is spending in connection with the audit as well
            as any other costs related to the audit, including the auditor.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">14.Cooperation</p>
          <p>
            The Simple Department will cooperate with you in order to ensure
            compliance with applicable data protection provisions, e.g. to
            enable you to effectively guarantee the exercise of data subjects’
            rights (right of access, rectification, erasure, blocking,
            opposition), to manage incidents including forensic analysis in case
            of security breach.{" "}
          </p>
          <span className="divider"></span>
          <p className="pricay-title">15. Terms of Service</p>
          <p>
            Please also visit our Terms of Service section establishing the use,
            disclaimers, and limitations of liability governing the use of our
            website at{" "}
            <a
              href="https://www.cookiebot.com/en/terms-of-service/"
              target="_blank"
            >
              www.cookiebot.com/en/terms-of-service
            </a>
            .{" "}
          </p>
          <span className="divider"></span>
          <p className="pricay-title">16. Your consent</p>
          <p>By using our site, you consent to this Privacy Policy. </p>
          <span className="divider"></span>
          <p className="pricay-title">17. Changes to our Privacy Policy</p>
          <p>
            If we decide to change our Privacy Policy, we will post those
            changes on this page, and/or update the Privacy Policy modification
            date below. This Privacy Policy was last modified on September 14th,
            2022.
          </p>
          <span className="divider"></span>
          <p className="pricay-title">18. Complaint</p>
          <p>
            You may at any time lodge a complaint with a supervisory authority
            regarding The Simple Department’s collection and processing of your
            personal data. In Canada, you can lodge a complaint with the
            Canadian Data Protection Agency.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Privacy;
